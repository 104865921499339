import React from 'react';
import CTA from './CTA';
import HeaderSocials from './HeaderSocials';
import './header.css';
import { Helmet } from "react-helmet";
const Header = () => {
 
const currentUrl = window.location.href;
  return (
    <header id="home">
      <Helmet>
  <title></title>
  <meta name="description" content="Explore our cutting-edge AR, VR, and ERP solutions portfolio at MetXR Studios." />
  <meta name="robots" content="index, follow"/>
  <link rel="canonical" href={currentUrl} />
  <meta name="keywords" content="AR solutions, VR software, ERP tools, MetXR Studios, augmented reality, virtual reality,AR,VR,ERP,CRM,Met,XR,WebApp,Websites" />
</Helmet>

      <div className="container header__container">
        <h1>MetXR Studios</h1>
       
        <CTA />
        {/* <img src={imgg} alt="main" className='imgg10'/> */}
        <a href="#contact" className="scroll__down">
          Scroll Down
        </a>
        <HeaderSocials />
      </div>
    </header>
  );
};

export default Header;
