import React from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';
import { Helmet } from "react-helmet";
const HeaderSocials = () => {
  const currentUrl = window.location.href;
  return (
    <div className="header__socials">
      <Helmet>
  <title></title>
  <meta name="description" content="Explore our cutting-edge AR, VR, and ERP solutions portfolio at MetXR Studios." />
  <meta name="robots" content="index, follow"/>
  <link rel="canonical" href={currentUrl} />
  <meta name="keywords" content="AR solutions, VR software, ERP tools, MetXR Studios, augmented reality, virtual reality,AR,VR,ERP,CRM,Met,XR,WebApp,Websites" />
</Helmet>

      <a href="https://www.linkedin.com/company/metxrstudios" target="_blank" rel="noreferrer" ><BsLinkedin /></a>
  
    </div>
  )
}

export default HeaderSocials