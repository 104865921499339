import React from 'react';
import { Helmet } from "react-helmet";

const CTA = () => {
  const currentUrl = window.location.href;
  return (
    <div className="cta">
      {/* <a href={CV} download className="btn">
        Download CV
      </a> */}
     <Helmet>
  <title></title>
  <meta name="description" content="Explore our cutting-edge AR, VR, and ERP solutions portfolio at MetXR Studios." />
  <meta name="robots" content="index, follow"/>
  <link rel="canonical" href={currentUrl} />
  <meta name="keywords" content="AR solutions, VR software, ERP tools, MetXR Studios, augmented reality, virtual reality,AR,VR,ERP,CRM,Met,XR,WebApp,Websites" />
</Helmet>

      <a href="#contact" className="btn btn-primary dancing-doll">
        Let's talk
      </a>
    </div>
  );
};

export default CTA;
