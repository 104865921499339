import React from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';
import './footer/footer.css';
import { Helmet } from "react-helmet";
const FooterA = () => {
  const currentUrl = window.location.href;
  return (
    <footer style={{marginTop:10,height:50,textAlign:"center"}}>
      {/* <a href="#home" className="footer__logo">MetXR Studios</a>
      <ul className="permalinks">
        <li><a href="#home">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Skills</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul> */}
     <Helmet>
  <title></title>
  <meta name="description" content="Explore our cutting-edge AR, VR, and ERP solutions portfolio at MetXR Studios." />
  <meta name="robots" content="index, follow"/>
  <link rel="canonical" href={currentUrl} />
  <meta name="keywords" content="AR solutions, VR software, ERP tools, MetXR Studios, augmented reality, virtual reality,AR,VR,ERP,CRM,Met,XR,WebApp,Websites" />
</Helmet>

      <div className="footer__copyright" >
        <small>&copy; MetXR Studios 2024. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default FooterA