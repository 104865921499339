import React from 'react';
import { BsFillPatchCheckFill } from 'react-icons/bs';
import './experience.css';
import { Helmet } from "react-helmet";
const Experience = () => {
  const currentUrl = window.location.href;
  return (
    <section id="experience">
     <Helmet>
  <title></title>
  <meta name="description" content="Explore our cutting-edge AR, VR, and ERP solutions portfolio at MetXR Studios." />
  <meta name="robots" content="index, follow"/>
  <link rel="canonical" href={currentUrl} />
  <meta name="keywords" content="AR solutions, VR software, ERP tools, MetXR Studios, augmented reality, virtual reality,AR,VR,ERP,CRM,Met,XR,WebApp,Websites" />
</Helmet>

      {/* <h2>Skills</h2> */}
      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Design & Development</h3>
          <div className="experience__content">
          <article className="experience__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <h4>UI/UX DESIGN</h4>
            </article>
            <article className="experience__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <h4>GRAPHIC DESIGN</h4>
            </article>
            <article className="experience__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <h4>3D MODELLING</h4>
            </article>
            <article className="experience__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <h4>DRONE PHOTOGRAPHY</h4>
            </article>
            <article className="experience__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <h4>IT CONSULTATION</h4>
            </article>
            <article className="experience__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <h4>3D WEB APP</h4>
            </article>
            <article className="experience__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <h4>3D PRODUCT DEMOS</h4>
            </article>
            <article className="experience__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <h4>DIGITAL MARKETING</h4>
            </article>
            <article className="experience__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <h4>PROJECT CONSULTATION</h4>
            </article>
            <article className="experience__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <h4>SEO & ADS</h4>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience